<template>
  <div class="team pad-x">
    <div class="container-fluid">
      <div class="team-wrapper">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/lili.png"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Lililashka</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/lililashka">@lililashka</a>
              </div>
              <p>Founder of the lost memes</p>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/yomi.png"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>YOMI</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/YOMI_WEB3">@YOMI_WEB3</a>
              </div>
              <p>Web3 Tech company</p>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/torben.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Torben Jacobi</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/blnft_official">@blnft_official</a>
              </div>
              <p>
                Founder at
                <a class="green" href="https://blnft.io" target="_blank"
                  >blnft.io</a
                >, art,<br />
                Collectibles and society track lead
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/irena.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Irena Satkeová</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/IrenaSatke">@IrenaSatke</a>
              </div>
              <p>Hackathon lead</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/Jasmine.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Jasmine</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/jasminervaa">@jasminervaa</a>
              </div>
              <p>Co-founder, a&t capital</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/esteve.png"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Esteve Serra Clavera</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/nosoccomtothom">@nosoccomtothom</a>
              </div>
              <p>Web3 adventurer</p>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/david.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>David Alexander Oster</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/ExplorerDavid">@ExplorerDavid</a>
              </div>
              <p>NFTinja & DAOlin</p>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/gregor.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Gregor Schmidt</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/missing">@missing</a>
              </div>
              <p>
                Creative direction at -
                <a class="green" href="blnft.io" target="_blank">blnft.io</a
                ><br />
                art, collectibles and society<br />
                track lead
              </p>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/ricardo2.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Ricardo Oswald</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/rxwald">@rxwald</a>
              </div>
              <p>
                Web3 + Talent Acquisition at
                <a href="https://mkt.market/" target="_blank" class="green">
                  mkt.market</a
                >
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/nich.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Nichanan Kesonpat</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/nichanank">@nichanank</a>
              </div>
              <p>
                Head of Platform at 1kx <br />
                NFTBerlin Finance Track Lead
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/canan.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Canan Cön</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/CoenCanan">@CoenCanan</a>
              </div>
              <p>
                Production/ Project Management <br />
                GarbiczFestival, BLNFT, Holzmarkt
              </p>
            </div>
          </div>
        </div>

        <h2 class="operation">Operation</h2>
        <div class="row">
          <div class="col">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/victor pfp.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Victor</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/victoctero">@victoctero</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/tara_pfp.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Tara</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/grounded_t">@grounded_t</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/flavia_pfp.png"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Flavia Bon</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/bon_flavia">@bon_flavia</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/yusuf_pfp.jpeg"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Barbaros</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/yusuf_barbaros">@yusuf_barbaros</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="member">
              <div class="member-ph">
                <img
                  src="../assets/team/sergio_pfp.png"
                  alt="member image"
                  draggable="false"
                />
              </div>
              <h1>Sergio</h1>
              <div class="member-social">
                <img src="../assets/icon-twitter.svg" draggable="false" />
                <a href="https://twitter.com/esdotge">@esdotge</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style></style>
