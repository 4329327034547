<template>
  <div class="new-section">
    <div class="speakers-new">
      <div class="ex-title" style="margin-bottom: 2.8rem">SPEAKERS</div>
      <!-- FIRST ROW -->
      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image6.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Aleksandra<br />
              Smilek
            </h1>
            <p>Creative Director<br />//Foresight Institute</p>
            <a
              href="https://twitter.com/foresightinst"
              class="tw-handle"
              target="_blank"
              >@foresightinst</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image2.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>Andy<br />Chorlian</h1>
            <p>Founder<br />//Fractional.art</p>
            <a
              href="https://twitter.com/andy8052"
              class="tw-handle"
              target="_blank"
              >@andy8052</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image11.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Stani<br />
              Kulechov
            </h1>
            <p>Founder<br />//AAVE & Lens Protocol</p>
            <a
              href="https://twitter.com/StaniKulechov"
              class="tw-handle"
              target="_blank"
              >@StaniKulechov</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image1.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>Lenara</h1>
            <p>
              Gardener <br />
              & Invisible Economist<br />//StatelessDAO
            </p>
            <a
              href="https://twitter.com/Lenara"
              class="tw-handle"
              target="_blank"
              >@Lenara</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image5.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Raffaella<br />
              Rein
            </h1>
            <p>Founder<br />//CareerFoundry</p>
            <a
              href="https://twitter.com/raffaelarein"
              class="tw-handle"
              target="_blank"
              >@raffaelarein</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image7.jpg"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Julien<br />
              Bouteloup
            </h1>
            <p>Founder<br />//RektHQ, BlackPool</p>
            <a
              href="https://twitter.com/bneiluj"
              class="tw-handle"
              target="_blank"
              >@bneiluj</a
            >
          </div>
        </div>
      </div>

      <!-- SECOND ROW -->

      <div class="row" id="toggle-speakers">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image4.jpg"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Lili<br />
              Feyerabend
            </h1>
            <p>Web3 Designer, <br />Experimentalist</p>
            <a
              href="https://twitter.com/lililashka"
              class="tw-handle"
              target="_blank"
              >@lililashka</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image10.jpg"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>Viktor<br />Diordiiev</h1>
            <p>Token Economist</p>
            <a
              href="https://twitter.com/vdiordiiev"
              class="tw-handle"
              target="_blank"
              >@VDiordiiev</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image9.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Ilan<br />
              Katin
            </h1>
            <p>OG crypto artist, Founder<br />//StatelessDAO</p>
            <a
              href="https://twitter.com/ilan_katin"
              class="tw-handle"
              target="_blank"
              >@ilan_katin</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image13.jpg"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>Leila <br />Ismailova</h1>
            <p>Special projects curator<br />//Artisant</p>
            <a
              href="https://twitter.com/artisantnft"
              class="tw-handle"
              target="_blank"
              >@Artisant</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image8.jpg"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Stephen<br />
              Young
            </h1>
            <p>Founder<br />//NFTfi</p>
            <a
              href="https://twitter.com/stephen_yo"
              class="tw-handle"
              target="_blank"
              >@stephen_yo</a
            >
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
          <div class="speaker">
            <div class="speaker-img-wrapper">
              <div class="speaker-bg"></div>
              <div class="speaker-img">
                <img
                  src="../assets/speakers/image12.png"
                  alt="speakers photo"
                  draggable="false"
                />
              </div>
            </div>
            <h1>
              Annette<br />
              Rolikova
            </h1>
            <p>//nftstandards.wtf</p>
            <a
              href="https://twitter.com/anettrolikova"
              class="tw-handle"
              target="_blank"
              >@anettrolikova</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="external-btn hideDesktop" @click="toggleSpeakers()">
      <button>
        <p>See all speakers</p>
        <span>
          <img src="../assets/icon-external-link.svg" draggable="false" />
        </span>
      </button>
    </div>

    <div class="external-btn fullprog">
      <a href="/talks">
        <button>
          <p>Full program</p>
        </button></a
      >
    </div>

    <div class="nft-ex">
      <div class="ex-title">NFT EXHIBITION</div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <h2>
            COZOMO <br />
            DE' MEDICI
          </h2>
          <p>
            Grand patron<br />
            of the digital arts
          </p>
          <a
            href="https://twitter.com/CozomoMedici"
            target="_blank"
            class="tw-handle"
            >@CozomoMedici</a
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <h2>
            Flamingo <br />
            DAO
          </h2>
          <p>
            A flamboyance of NFT collectors,<br />
            curators and artists
          </p>
          <a
            href="https://twitter.com/FLAMINGODAO"
            class="tw-handle"
            target="_blank"
            >@FLAMINGODAO</a
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <h2>
            Neon <br />
            DAO
          </h2>
          <p>
            A collective buildling <br />
            out the metaverse
          </p>
          <a
            href="https://twitter.com/neon_dao"
            class="tw-handle"
            target="_blank"
            >@neon_dao</a
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <h2>
            Kanosei <br />
            MetaGallery
          </h2>
          <p>
            The grand interactive<br />
            NFT gallery
          </p>
          <!-- <a href="" class="tw-handle" target="_blank">@Kanosei.xyz</a> -->
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <h2>
            30+ CryptoPunks <br />
            Exhibition
          </h2>
          <p>
            Legendary Punks exhibition<br />
            from various private collections
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpeakerSwiper.vue",

  data() {
    return {
      isSpeakersActive: false,
    };
  },

  methods: {
    toggleSpeakers() {
      this.isSpeakersActive = !this.isSpeakersActive;

      if (this.isSpeakersActive) {
        document.getElementById("toggle-speakers").style.display = "flex";
      } else {
        document.getElementById("toggle-speakers").style.display = "none";
      }
    },
  },
};
</script>