<template>
  <div class="events">
    <div class="container-fluid">
      <div class="events-content">
        <h1 class="title-dbw">Satellite <span>events</span></h1>
        <a href="#" class="external-btn">
          <button>
            <p>See all events</p>
            <span>
              <img src="../assets/icon-external-link.svg" alt="" />
            </span>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Events",
};
</script>

<style>
</style>