<template>
  <div class="team-swiper pad-x hideDesktop" style="display:none">
    <div class="container-fluid">
      <h1 class="title-dbw">Our <span>Team</span></h1>

      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="member">
            <div class="member-ph">
              <div class="ph-quad"></div>
              <img
                src="../assets/img-team.png"
                alt="member image"
                draggable="false"
              />
            </div>
            <h1>Kristin Watson</h1>
            <div class="member-social">
              <img src="../assets/icon-twitter.svg" draggable="false" />
              <p>@nicktwitter</p>
            </div>
            <p>
              Consectetur adipiscing elit duis tristique sollicitudin nibh sit
              amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus
              vitae congue
            </p>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <!-- BUTTONS -->
      <div class="swiper-btn-wrapper">
        <button class="prev-team-el">
          <img
            src="../assets/icon-prev.svg"
            alt="prev button"
            draggable="false"
          />
        </button>
        <button class="next-team-el">
          <img
            src="../assets/icon-next.svg"
            alt="next button"
            draggable="false"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "swiper-example-navigation",
  title: "Navigation",
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        centeredSlides: false,
        loop: true,

        navigation: {
          nextEl: ".next-team-el",
          prevEl: ".prev-team-el",
        },

        breakpoints:{
          768:{
            slidesPerView: 2.5,
            spaceBetween:0,
          },
          425:{
            slidesPerView: 1,
            spaceBetween:0,
          },
          375:{
            slidesPerView: 1,
            spaceBetween:0,
          },
          320:{
            slidesPerView: 1
          },
        }
      },
    };
  },
};
</script>