<template>
  <div class="talks">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-lg-1">
          <h3>Cross chain NFT Event</h3>
        </div>
        <div class="col-sm-12 col-lg-10">
          <div class="black-filter"></div>
          <div class="talks-date">
            <h1>
              May 25-27, 2022<br />
              <span>Alte Muenze, Berlin</span>
            </h1>
            <div class="position">
              <div>
                <img
                  src="../assets/icon-position.svg"
                  alt="icon position"
                  draggable="false"
                />
                <p>Alte Münze, Berlin</p>
              </div>
            </div>
          </div>
          <div class="talks-types">
            <div class="row">
              <h3 class="h3-border">Art, Collectibles & Society</h3>
              <h3 class="h3-border">Game & Experiences</h3>
              <h3 class="h3-border">Financialization</h3>
              <h3 class="">Infrastructure & Research</h3>
              <h3 class="">NFT Art Exhibition "The New Gestalt"</h3>
            </div>
          </div>
          <!-- <a href="https://yeruna5cyo1.typeform.com/to/hbCPVkbh" target="_blank"
            ><button><h3>You want to be a speaker, contact us!</h3></button></a
          > -->
        </div>
        <div class="col-sm-12 col-lg-1 hideIpad">
          <h3>Talks / Exhibitions / Games</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Talks",
};
</script>

<style>
</style>