<template>
  <div class="talks-page">
    <Navbar />
    <MenuMobile class="hideDesktop" />
    <div class="schedule-content">
      <div class="talk-scheduling">
        <h1>Talk<br />scheduling</h1>
        <div class="tags">
          <a href="/talks"><div class="tag active-link">DAY 1</div></a>
          <div class="tag active-day">DAY 2</div>
        </div>
      </div>
      <div class="room-a">
        <div class="rooms">
          <h1>LOOKS RARE STAGE #1<br />First floor, Main hall</h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>

            <!--         looks rare stage #1
First floor, main hall -->

            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.45</p></div>
                <div class="col">
                  <p>
                    Kerstin Gold - Co-founder, ART+TECH Report<br />
                    & Art Market Strategy Consultant <br />
                    Anna Graf - Director NFT, misa.art<br />
                    Diane Drubay - Arts and Culture Advisor <br />Tezos
                    Ecosystem<br />
                    TZ Connect Johanna Neuschäfer - Co-founder, OFFICE IMPART<br />
                    and ART+TECH Report
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Art NFT Collecting - What is different?</p>
                </div>
                <div class="col">
                  <p>
                    Art NFT collecting: What is different? The advent of NFTs
                    raises plenty of questions for the traditional art world:
                    what's their role and potential, and what are the
                    motivations for collecting art NFTs?
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.45-11.30</p></div>
                <div class="col">
                  <p>
                    Katia Yakovleva - Co-founder, Beyond Catriona <br />
                    Emily Furlong - Community Lead, Shark DAO<br />
                    & Co-founder, Otterspace <br />
                    Sam Madhu - Berlin based NFT artist<br />
                    Raffaela Rein - NFT collector
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>
                    The crucial role of community to set up your NFT project for
                    success
                  </p>
                </div>
                <div class="col">
                  <p>
                    Engaged and dedicated community is a backbone of every
                    successful NFT project. I would like to invite an artist, a
                    successful community builder and an NFT collector to share
                    specific insider tips.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.30-12.00</p></div>
                <div class="col">
                  <p>
                    Beatriz Helena Ramos <br />
                    Co-founder, DADA NYC
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>DADA + the Invisible Economy</p>
                </div>
                <div class="col">
                  <p>
                    We are NFT pioneers (First to introduce royalties on smart
                    contracts in 2017). Currently developing an alternative
                    socio economic system using blockchain & holochain (The
                    Invisible Economy).
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.30</p></div>
                <div class="col">
                  <p>Tina - Head of Design, Element Finance</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Yielding the future of NFT Opportunities</p>
                </div>
                <div class="col">
                  <p>
                    How we can bridge the intersection of defi protocols and
                    NFTs and visualize the contributions and actions of
                    community members past their portfolio balance.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.30-14.00</p></div>
                <div class="col">
                  <p>Chris Minnis - Core Contributor, Goblin Sax</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>The Trials and Tribulations of DAO Underwriting</p>
                </div>
                <div class="col">
                  <p>
                    Goblin Sax is the first NFT underwriter DAO providing loans
                    on NFTs via automated, manual, and DAO-to-DAO lending
                    strategies. Here we dive into our learnings from the first
                    season, where we go from here, and the role of financial
                    services DAOs in the future of NFT Finance.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>Zhivko Todorov - Co-founder, EnterDAO</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    Financial primitives for NFTs: Real Estate, Loans, Renting
                    and more
                  </p>
                </div>
                <div class="col">
                  <p>
                    Overview of different financial primitives surrounding NFTs,
                    trends, technological challenges
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-15.00</p></div>
                <div class="col">
                  <p>
                    Alex Cuthbertson - Co-founder <br />and Production Lead,
                    Dream World DAO
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    Patreon 2.0 - Using DeFi & PoS to support the Arts without
                    donations
                  </p>
                </div>
                <div class="col">
                  <p>
                    How artists, science and charities can leverage DeFi and
                    Validators to create recurring income at no expense to the
                    supporter
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.50</p></div>
                <div class="col">
                  <p>
                    Lili Feyerabend: <br />
                    Founder, NFTBERLIN<br />
                    Maria Paula Fernandez: <br />
                    Co-founder, JPEG<br />
                    Kaushik Swaminathan: <br />
                    CEO, Co-Museum<br />
                    David Toh: <br />
                    Partner, Mirana Ventures
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>
                    More than a jpeg: building a crypto-native cultural
                    institution
                  </p>
                </div>
                <div class="col">
                  <p>
                    If one of the primary forms of cryptocultural expression is
                    economics, what impact will this have on modes of ownership
                    and artistic production? Should we think about NFTs as a
                    medium or as a tool? A discussion on traditional forms of
                    patronage versus crypto-native cultural institutions.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.50-16.40</p></div>
                <div class="col">
                  <p>
                    Konstantin Tkachuk:<br />
                    Protocol Labs<br />
                    Philipp:<br />
                    Founder, Creco.xyz<br />
                    Shannon Wells:<br />
                    Head of Community and Governance, Livepeer<br />
                    Julien Bouteloup:<br />
                    Co-founder, Blackpool and StakeDAO<br />
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Cross-chain infrastructure for NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Discussion about liquidity and bootstrapping, UX challenges,
                    value of L2 assets and supply challenges for omni-chain
                    assets as well as interesting technical challenges with zk
                    rollups and more
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.40-17.00</p></div>
                <div class="col">
                  <p>
                    Isa Kivlighan: <br />
                    Product Marketing, The Fabricant
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Dressing Digi-Sapiens</p>
                </div>
                <div class="col">
                  <p>
                    As people spend more time in the Metaverse what will they
                    wear? This session will explore how we will create our
                    identites in the Metaverse and self-expression in digital
                    spaces through fashion.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-17.20</p></div>
                <div class="col">
                  <p>
                    Bozena Rezab:<br />
                    CEO, Gamee
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Why gaming will bring millions of users to blockchain</p>
                </div>
                <div class="col">
                  <p>
                    Gaming industry is bigger than music & film industry
                    combined, gamification became part of our daily lives. Lets
                    look into why and how gaming will become a massive adoption
                    force for NFTs and blockchain technology.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.20-17.35</p></div>
                <div class="col">
                  <p>
                    Simone Conti: <br />
                    Head of Digital Asset Trading and Yielding, Dialectic
                  </p>
                </div>
                <div class="col">
                  <p>Solo Talk</p>
                </div>
                <div class="col">
                  <p>Look Mum, I became a gaming investor</p>
                </div>
                <div class="col">
                  <p>
                    As early believers in the p2e space, we talked to over 200
                    founders and here are our lessons learned.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17:35-17:50</p></div>
                <div class="col">
                  <p>
                    Kaya Tilev:<br />
                    Founder, Lava Labs
                  </p>
                </div>
                <div class="col">
                  <p>Solo Talk</p>
                </div>
                <div class="col">
                  <p>So you want to build an NFT game?</p>
                </div>
                <div class="col">
                  <p>Real-world lessons learned in building and scaling</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.50-18.00</p></div>
                <div class="col">
                  <p>
                    Lukas Amacher:<br />
                    Head Curator, Dialectic
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>WTF are collector DAOs?</p>
                </div>
                <div class="col">
                  <p>
                    Creator DAOs are a relatively new paradigm in art
                    collecting. The field is expansive, and many waters remain
                    uncharted. This talk will dive into mechanics underlying
                    various collector DAOs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="schedule-divider"></div>

        <!-- ROOM 2 -->

        <div class="rooms">
          <h1>
            GM STAGE #2:<br />
            First floor, opposite to the main hall
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.20</p></div>
                <div class="col">
                  <p>
                    Sergio:<br />
                    Co-founder, <br />FLOC
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Brand3: Empowering Web3 communities through NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Originally, companies wanted to be brands. Now brands want
                    to be communities. Brand3 is the era of user-owned brands. A
                    new relationship where everything is still to be built.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.20-10.40</p></div>
                <div class="col">
                  <p>
                    Simon:<br />
                    Designer <br />and Entreprenuer
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>The Future of Fashion in the Metaverse</p>
                </div>
                <div class="col">
                  <p>
                    Digital fashion use-cases, potential of collaboration and
                    co-creation inside the Metaverse and the relevance of DAOs
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.40-11.00</p></div>
                <div class="col">
                  <p>
                    Simon Weniger <br />
                    Co-founder, Mintgate
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>NFTs as memberships and access keys</p>
                </div>
                <div class="col">
                  <p>
                    This talk is about how creators and community-focused brands
                    can create a membership through NFT ownership. And how
                    owning digital content can be even more valuable through
                    gated access.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.00-11.20</p></div>
                <div class="col">
                  <p>
                    jiwa:<br />
                    Artist <br />and Founder, ntent.art
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Generative Art: We need a new vocabulary</p>
                </div>
                <div class="col">
                  <p>
                    Generative Art is more than just illustrations compiled to
                    create a 10k collection. It's a brand new medium with a
                    variety of niches. What are those niches? How do we talk
                    about them?
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.20-11.40</p></div>
                <div class="col">
                  <p>Miguel Faus - Screenwriter and Director</p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>How NFTs can change Cinema</p>
                </div>
                <div class="col">
                  <p>
                    Cinema is still the most centralized art form in the world,
                    and web3 can change that from financing to distribution
                    stage. We will empower filmmakers by empowering audiences.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.40-12.00</p></div>
                <div class="col">
                  <p>Oliver Löffler</p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Angry Dynomites Lab</p>
                </div>
                <div class="col">
                  <p>The Worlds first massive Co-Op Game</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.30</p></div>
                <div class="col">
                  <p>
                    Johan Unger <br />
                    Founder, meme.com
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Memes are the story of the internet</p>
                </div>
                <div class="col">
                  <p>
                    A look at impactful memes through the history of the NFT
                    space, and how NFTs are powerful tools in the meme economy.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.30-14.00</p></div>
                <div class="col">
                  <p>
                    Phil Galaviz:<br />
                    Head of NFT, REEF
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Graffiti artists x NFT</p>
                </div>
                <div class="col">
                  <p>
                    We've found a niche spot for graffiti artists and NFTs -
                    bring NFTs into IRL. We're working hard to move the digital
                    NFT island to a global NFT nation, and street style.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>
                    Alessandro Pericò <br />
                    CEO & Product Architect,<br />
                    Skillwallet
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>The State of Identity</p>
                </div>
                <div class="col">
                  <p>
                    Reflection on what Identity is - both at the blending of
                    digital & physical, & at the collision of metaverses. What's
                    the "State of Identity" (tools, interactions, ...), & a
                    self-sovereign ID-state.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-14.50</p></div>
                <div class="col">
                  <p>
                    Shrug Newton: <br />
                    Co-founder, Double Protocol
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    [VIRTUAL / DIAL IN] First Principles of Building an NFT
                    Rental Protocol
                  </p>
                </div>
                <div class="col">
                  <p>
                    Will cover all current solutions and the evolution of the
                    NFT rental market. From over-collateralization to wrap to
                    dual role standards and smart wallet solutions.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.50-15.20</p></div>
                <div class="col">
                  <p>
                    Cameron Robertson:<br />
                    Co-founder, Kong.cash
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>NFTs IRL: Why Physical Crypto Needs Silicon</p>
                </div>
                <div class="col">
                  <p>
                    Crypto assets in the real world is the next frontier that
                    will bring NFTs and tokens mainstream. KONG Land is making
                    secure chips for intuitive, self-custodial physical crypto
                    assets.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.20-15.50</p></div>
                <div class="col">
                  <p>
                    Marina Grujic: <br />
                    Growth Manager, NFTgo
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Why are analytics important in NFTs?</p>
                </div>
                <div class="col">
                  <p>
                    We will be talking about analytics in NFTs through the NFTGo
                    platform, and how deep can you go in getting to know your
                    desired market.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.50-16.10</p></div>
                <div class="col">
                  <p>
                    Richard Muirhead: <br />
                    Co-founder, Fabric Ventures
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>
                    Examining the Future of NFTs for Artists, Sports Figures,
                    Celebrities and Consumer Facing Applications
                  </p>
                </div>
                <div class="col">
                  <p>
                    NFT as a new business model We are investors at NEAR,
                    Polkadot, Immutable, Dapper Labs, CAH, we would love to
                    share our research on NFT market with an audience.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.10-18.00</p></div>
                <div class="col">
                  <ol>
                    <li>
                      Oaksprout the Tan <br />
                      Full Stack Autonomist, Autonolas
                    </li>
                    <li>David Call to Crypto <br />Co-founder, Gloomins</li>

                    <li>
                      Ania Pilipenko
                      <br />
                      CO-NFT, Metawalls
                    </li>
                    <li>
                      Ryan Groves
                      <br />
                      CTO and Co-founder
                      <br />
                      Infinite Album
                    </li>
                    <li>
                      Dr. Annette Doms
                      <br />
                      Partner and Head of Curation,
                      <br />
                      Metadibs®
                    </li>
                    <li>
                      Claudio Weck
                      <br />
                      CTO and Co-founder,
                      <br />
                      fanzone.io
                    </li>
                    <li>Chris & Chris</li>
                    <li>
                      Marius Morhard
                      <br />
                      Developer, vyking.io
                    </li>
                    <li>
                      Csongor Barabasi
                      <br />
                      Co-founder, FUEL
                    </li>
                    <li>
                      Zack Sabban
                      <br />
                      Co-founder and Co-CEO, RLTY
                    </li>
                  </ol>
                </div>
                <div class="col">
                  <p>Mixed</p>
                </div>
                <div class="col">
                  <p>Short demos (8 mins each)</p>
                </div>
                <div class="col">
                  <ol>
                    <li>
                      El Collectooorr: the Dawn of the (Actually) Autonomous NFT
                      DAO
                    </li>
                    <li>Intro to Gloomins NFT</li>
                    <li>Street Art & tokenomics</li>
                    <li>
                      Infinite NFTs: Turning Gamers into Creators with AI Music
                    </li>
                    <li>Metadibs® longterm eco-friendly - how it works!</li>
                    <li>
                      NFTs decentral and easy for all sports fans: ERC725
                      Universal Profiles & Apps
                    </li>
                    <li>Apoll X</li>
                    <li>Flex your NFTs by wearing them</li>
                    <li>
                      Making NFTs simple and accessible for Europe, Fuel
                      protocol
                    </li>
                    <li>Metaverse Event Infrastructure</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ROOM 3 -->

        <div class="rooms">
          <h1>
            MOON STAGE #3:<br />
            Top floor, Olivia Studio
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.30</p></div>
                <div class="col">
                  <p>
                    Josef Je <br />
                    Co-founder and CEO, PWN Finance
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>TMortgages for crypto natives</p>
                </div>
                <div class="col">
                  <p>
                    We want to unlock all the value stored in any digital asset
                    and allow it to be deployed in the real world.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.30-11.00</p></div>
                <div class="col">
                  <p>
                    Jerome de Tychey <br />
                    Founder, Cometh
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Crafting DeFi backed NFT and lending them in a game</p>
                </div>
                <div class="col">
                  <p>
                    A showcase of smart contracts that allow for crafting and
                    grinding NFT via deposit in DeFi protocols. This is combined
                    with NFT lending to offer complete game asset
                    commoditization. Includes a short demo in the context of our
                    newly released game (since early May) and explain how other
                    projects can leverage it
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.00-11.30</p></div>
                <div class="col">
                  <p>
                    Stani Kulechov:<br />
                    Founder, Lens & Aave Protocols
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Web3 Social: Live Footage of the Lens Protocol</p>
                </div>
                <div class="col">
                  <p>
                    Say goodbye to Twitter. Here is a talk from a OG web3
                    frontline builder on why a composable and decentralized
                    social graph is the future.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.20</p></div>
                <div class="col">
                  <p>
                    Raffaela Rein <br />
                    NFT collector
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>
                    How to build an NFT collectors group - lessons from the
                    Proof Collective
                  </p>
                </div>
                <div class="col">
                  <p>
                    Collecting NFTs is more fun as a group & you can leverage
                    the group to find cool projects earlier. This talk goes into
                    tips with Lessons from The Proof Collective, a well known
                    NFT collectors group
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.20-13.40</p></div>
                <div class="col">
                  <p>
                    Allison Duetmann <br />
                    CEO, Foresight Institute <br />Aleksandra Smilek <br />
                    Creative technologist, Foresight Institute
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Decentralizing science & bridging art</p>
                </div>
                <div class="col">
                  <p>
                    Discuss how NFTs can bridge scientists and artists to craft
                    and imagine potential futures, decentralize the way we fund
                    science and collaborate on positive futures.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.40-14.00</p></div>
                <div class="col">
                  <p>
                    Sofiane Delloue <br />
                    Founder, Newcoin
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Social 3.0 and the Ownership Economy</p>
                </div>
                <div class="col">
                  <p>
                    DeFi, DAOs and NFTs are becoming adopted standards for price
                    discovery of both financial and creative assets. The
                    remaining challenge is to bundle them into a consumer-ready
                    holistic user experience.
                  </p>
                </div>
              </div>

              <div class="row programrow">
                <div class="col schedule-hours"><p>14.20-14.40</p></div>
                <div class="col">
                  <p>Richard Harless <br />Digitalstage.io</p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>
                    Connected reality: using NFTs to gamify real-world fan
                    experiences.
                  </p>
                </div>
                <div class="col">
                  <p>
                    This session will discuss how artists and brands use NFTs to
                    create and connect web3 rewards to real-world games and
                    engagements using AR/VR and the Metaverse.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.40-15.00</p></div>
                <div class="col">
                  <p>
                    Ekin Eris <br />
                    Infinite Arcade
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Web3 Gaming Economics: Is it sustainable?</p>
                </div>
                <div class="col">
                  <p>
                    Economies of top P2E games are struggling. Session will
                    strive to address the key components of P2E gaming
                    economics, and presenting a new set of hypotheses to achieve
                    sustainability in the long run.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.45</p></div>
                <div class="col">
                  <p>
                    Stephen Young - Founder and CEO, NFTfi <br />Diego -
                    Co-founder, Gradient Protocol<br />
                    Josef Je - Co-founder and CEO, PWN Finance <br />Benjamin
                    Nelkin - Builder, Themis <br />Jen Kim - Head of Product,
                    NFTBank
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>NFT Finance</p>
                </div>
                <div class="col">
                  <p>
                    A discussion with projects building NFT financialization
                    infrastructure, from NFT-backed loans to valuation
                    approaches
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.45-16.30</p></div>
                <div class="col">
                  <p>
                    Richie Hawtin<br />Electronic musician <br />Tyler Golato<br />
                    Co-founder and CSO, Molecule
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>A Dialogue with Richie Hawtin</p>
                </div>
                <div class="col">
                  <p>
                    Richie Hawtin, award-winning DJ, will share with us his
                    experiences and views on music NFT: Past, Present and Future
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.30-17.00</p></div>
                <div class="col">
                  <p>
                    Andy Gray <br />
                    Founder, KnownOrigin
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Explosion and History of NFT Art</p>
                </div>
                <div class="col">
                  <p>
                    We have been selling NFT cryptoart on KnownOrigin since 2018
                    and have a lot to playback
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-18.00</p></div>
                <div class="col">
                  <p>BlackPool</p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Non Fungible Auction by BlackPool</p>
                </div>
                <div class="col">
                  <p>
                    After the Lisbon, Barcelona and Paris editions, now the city
                    of engaged artists, inclusiveness to host the next edition
                    of the unique decentralised charity auction in traditional
                    vintage format.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ROOM 4 -->

        <div class="rooms">
          <h1>
            THE LAB #4:<br />
            First floor, foyer in front of the main hall
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.30</p></div>
                <div class="col">
                  <p>
                    Lenara:<br />
                    Co-founder, StatelessDAO<br />
                    Ilan Katin:<br />
                    Co-founder, StatelessDAO"
                  </p>
                </div>
                <div class="col">
                  <p>Solo Talk</p>
                </div>
                <div class="col">
                  <p>StatelessDAO: A cooperative NFT protocol</p>
                </div>
                <div class="col">
                  <p>
                    Why a cooperative NFT platform? The cooperative model allows
                    a significant portion of each sale to be shared equally
                    among members and also used to fund maintenance and other
                    projects, as decided by an integrated DAO.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.30-11.00</p></div>
                <div class="col">
                  <p>James Morgan - CTO, Knownorigin</p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>The State of NFT Royalties</p>
                </div>
                <div class="col">
                  <p>
                    Talk describing current NFT smart contract standards,
                    highlighting issues with ERC-721, ERC-1155 and potential
                    solutions. Representing NFT Standards research and
                    documentation
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.00-11.30</p></div>
                <div class="col">
                  <p>
                    Rebekka Revel <br />
                    Academician and Artist
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Creating Culture with the material and immaterial</p>
                </div>
                <div class="col">
                  <p>
                    My expertise as a social studies scholar, and experience as
                    an artist and community manager of the NFT Club Berlin
                    combines a unique perspective that combines concepts, art
                    and the NFT world together.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.30-12.00</p></div>
                <div class="col">
                  <p>
                    Beltran Berrocal<br />
                    Co-founder, Zero Labs
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Fighting the climate crisis with (non-visual) NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Renewable Energy Certificate NFTs are a web3 building block
                    that power the the programmable decarbonization revolution,
                    enabling businesses and individuals to easily buy renewable
                    energy and prove it
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>12:45-13:30</p></div>
                <div class="col">
                  <p>
                    Zuzana Bastian:<br />
                    Founder of JEVELS<br />
                    Amber Slooten:<br />
                    Co-Founder and Creative Director of The Fabricant<br />
                    Lauren Kacher:<br />
                    Founder of Alterrage<br />
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>NFT fashion wearable through AR and VR</p>
                </div>
                <div class="col">
                  <p>
                    Everyone wants to look good, buy clothes, accessories. BUT
                    everyone knows about the harming effects of the fashion
                    industry. And what about our virtual presence on social
                    media, video calls and in the Metaverse? We need to talk
                    about the numerous web3 approaches benefiting the designers
                    and public, generating value for the entire community by
                    leveraging from the amazing technologies like the blockchain
                    and XR. Because NFT fashion is changing the way we live!
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.30-14.00</p></div>
                <div class="col">
                  <p>
                    Fabian Stelzer <br />
                    Tech Entrepreneur
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>
                    Loreplaying: decentrally narrated worldbuilding in NFT
                    communities
                  </p>
                </div>
                <div class="col">
                  <p>
                    This is brief talk about "loreplaying", a novel form of
                    media and community play involving decentralized narration
                    and co-creative worldbuilding that's happening in some NFT
                    communities.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>
                    Winston Robson:<br />
                    Co-founder and CEO, WeMeta
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Pricing the Metaverse</p>
                </div>
                <div class="col">
                  <p>
                    What drives value in the Metaverse? Is land scarce? How can
                    I understand what’s going on? Where is the data?
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-15.00</p></div>
                <div class="col">
                  <p>
                    Camilla McFarland <br />
                    VP of Partnership, MojitoNFT
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    DeFi and NFTs: How They Mix & Why They Matter in the
                    Metaverse
                  </p>
                </div>
                <div class="col">
                  <p>
                    The financialization of NFTs goes so much farther than just
                    how artists & collectors are making money: NFTs give DeFi a
                    new role in the metaverse & are the backbone of a new
                    creator economy.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.30</p></div>
                <div class="col">
                  <p>
                    Yan Meng <br />
                    Co-founder, Solv Protocol
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    [VIRTUAL / DIAL IN] Tokenizing and bringing liquidity to
                    smart contracts
                  </p>
                </div>
                <div class="col">
                  <p>
                    NFTs can be the solution for one of the most pressing
                    problems in the emerging field of DeFi: the absence of an
                    efficient and flexible tool to express sophisticated
                    financial contracts.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.30-16.30</p></div>
                <div class="col">
                  <ol>
                    <li>
                      Claus Widmaier Redrivar <br />
                      Cook and Artist
                    </li>
                    <li>
                      Bluemoon <br />
                      ntent.art
                    </li>
                    <li>Nils Duerr - Co-founder <br />& CTO, Pinsl.com</li>
                    <li>
                      Anna Mehrländer <br />
                      Pools and Partnership<br />
                      Manager, Centrifuge
                    </li>
                    <li>
                      Canan Coen
                      <br />
                      General Manager<br />
                      Production, BLNFT
                    </li>
                    <li>
                      w3 fund <br />
                      Why the web 3 space <br />needs web 2 brands to enter
                    </li>
                  </ol>
                </div>
                <div class="col">
                  <p>Mixed</p>
                </div>
                <div class="col">
                  <p>Short demos (8 mins each)</p>
                </div>
                <div class="col">
                  <ol>
                    <li>Life after</li>

                    <li>Onboarding of the fine art world in the metaverse</li>
                    <li>How NFTs are used in creating RWA DeFi</li>
                    <li>Holzmarkt project</li>
                  </ol>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16:30-17:00</p></div>
                <div class="col">
                  <p>
                    Fabian Wetekamp:<br />
                    Member, TheLAO and other various Tribute Labs DAOs Stefano
                    Sandri:<br />
                    Member, TheLAO and other various Tribute Labs DAOs
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>DAOs and the future of work</p>
                </div>
                <div class="col">
                  <p>
                    We'll be talking how DAOs shape the workforce of Web3 and
                    how members of DAOs benefit from the hive-mind. TheLAO is
                    one of the oldest and most prominent for-profit investment
                    DAOs in the space.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-17.30</p></div>
                <div class="col">
                  <p>
                    Dennis Lisk: <br />
                    Founder and CEO The District VR, Co-Founder Fabian
                    Vogelsteller,Blockchain Architect and CTO Ioulia Isserlis
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Virtual Live Events in Web3D</p>
                </div>
                <div class="col">
                  <p>
                    Music Culture + Virtual Reality + Blockchain = The District
                    VR
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.30-18.00</p></div>
                <div class="col">
                  <p>
                    Dennis: Peqas <br />
                    Founder, NFT Club Berlin
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Local NFT community</p>
                </div>
                <div class="col">
                  <p>
                    A local community helps the city to grow and iterate faster.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MenuMobile from "@/components/MenuMobile.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "TalksPageTwo",

  components: {
    Navbar,
    MenuMobile,
    Footer,
  },

  methods: {},
};
</script>

<style>
</style>