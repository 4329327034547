<template>
  <div class="what hideDesktop">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="pad-x">
            <div class="what-title">
              <h1>Why</h1>
              <h1>Nft Berlin?</h1>
            </div>

            <div class="what-text">
              <p>
               We aim to foster, to the greatest possible extent, meaningful connections between the participants and to drive this movement forward by providing an IRL space to level the playing field between indie NFT makers and established companies entering the market. Our ambition is to become the most extensive IRL platform for NFT builders and creators in Europe.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <!-- <img :src="image" draggable="false" >  -->
          <img src="../assets/img-graffiti.png" alt="" />
        </div>
      </div>
    </div>

   
  </div>
</template>

<script>


export default {
  name: "Why",

  
};
</script>

<style>
</style>