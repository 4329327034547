var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket manage-tickets"},[_c('Navbar'),_c('MenuMobile',{staticClass:"hideDesktop"}),_c('div',{staticClass:"gap hideMobile"}),_c('div',{staticClass:"container mb-5",class:{ 'mt-5': !_vm.isMobile }},[_c('div',{staticClass:"title-container pt-3 pb-3"},[_c('h1',{staticClass:"green_inactive"},[_vm._v("MY ACCOUNT")]),(!_vm.transferCompleted && !_vm.claimCompleted)?_c('div',[_c('h2',{staticClass:"green"},[_vm._v("nftberlin")]),_c('div',{staticClass:"specs-location d-flex align-items-start",class:{ 'mt-2': !_vm.isMobile, 'mt-2': _vm.isMobile }},[_c('h4',[_vm._v("27th - 28th May")]),_vm._m(0)])]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-7"},[(!_vm.account)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"btn-mint",on:{"click":function($event){return _vm.connect()}}},[_vm._v("Connect Wallet")])]):_vm._e(),_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(
              !_vm.isClaiming &&
              _vm.claimCompleted &&
              _vm.selected.metadata !== undefined &&
              _vm.claimed.qr !== undefined
            )?_c('div',{staticClass:"claim-tickets mt-5"},[_c('div',{staticClass:"title-container pb-4 d-flex justify-content-between"},[_c('h2',{staticClass:"green m-0"},[_vm._v("success!")]),(_vm.isMobile)?_c('div',[_c('div',{staticClass:"sqr-btn",on:{"click":function($event){_vm.tokenId = '';
                    _vm.selected = {};
                    _vm.initClaimProcess = false;
                    _vm.initSendProcess = false;
                    _vm.ticketSelection = false;
                    _vm.claimCompleted = false;
                    _vm.connect();}}},[_c('i',{staticClass:"fa-solid fa-arrow-left"})])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center ticket-select b-bottom b-top py-5"},[_c('div',{staticClass:"ticket-box-container"},[_c('div',{staticClass:"ticket-box m-0"},[_c('div',[_c('img',{attrs:{"src":_vm.selected.metadata.image.replace(
                          'ar://',
                          'https://arweave.net/'
                        ),"alt":"ticket NFT Berlin"}})])])]),_c('div',{class:{ 'ml-5 pl-5': !_vm.isMobile }},[_c('h4',{staticClass:"green",class:{ 'text-start mt-3': _vm.isMobile }},[_vm._v(" see you "),_c('br'),_vm._v(" in berlin."),_c('br'),_c('br'),_vm._v(" /////"),_c('br'),_vm._v(" ////"),_c('br'),_vm._v(" ///"),_c('br'),_vm._v(" // ")])])]),(_vm.claimed.qr !== undefined)?_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center qr-generated mt-5"},[_c('div',{class:{ 'text-center': _vm.isMobile }},[_c('div',{staticClass:"qr-code"},[_c('img',{attrs:{"src":_vm.claimed.qr}}),_c('div',{staticClass:"qr-code-under"})])]),_c('div',{class:{ 'ml-5': !_vm.isMobile }},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation"}),_c('h3',{staticClass:"mt-3 white"},[_vm._v("Wait for your QR-Code:")]),_c('h3',{staticClass:"grey"},[_vm._v(" A QR code will be sent directly to your email address. Make sure you have it with you (either in digital form or printed out) to guarantee admission to an event. ")]),_c('h3',{staticClass:"red"},[_vm._v(" * if you sell this NFT the claiming will be rejected ")])])]):_vm._e()]):_vm._e()]),_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(
              !_vm.isSending &&
              _vm.transferCompleted &&
              _vm.selected.metadata !== undefined &&
              _vm.pending !== undefined
            )?_c('div',{staticClass:"claim-tickets mt-5"},[_c('div',{staticClass:"title-container pb-4 d-flex justify-content-between"},[_c('h2',{staticClass:"green m-0"},[_vm._v("success!")]),(_vm.isMobile)?_c('div',[_c('div',{staticClass:"sqr-btn",on:{"click":function($event){_vm.tokenId = '';
                    _vm.selected = {};
                    _vm.initClaimProcess = false;
                    _vm.initSendProcess = false;
                    _vm.ticketSelection = false;
                    _vm.claimCompleted = false;
                    (_vm.transferCompleted = false), _vm.connect();}}},[_c('i',{staticClass:"fa-solid fa-arrow-left"})])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center ticket-select b-bottom b-top py-5"},[_c('div',{staticClass:"ticket-box-container"},[_c('div',{staticClass:"ticket-box m-0"},[_c('div',[_c('img',{attrs:{"src":_vm.selected.metadata.image.replace(
                          'ar://',
                          'https://arweave.net/'
                        ),"alt":"ticket NFT Berlin"}})])])]),_c('div',{staticClass:"ml-5 pl-5"},[_c('h4',{staticClass:"green"},[_vm._v(" Ticket transfered "),_c('br'),_vm._v(" correctly."),_c('br'),_c('br'),_vm._v(" /////"),_c('br'),_vm._v(" ////"),_c('br'),_vm._v(" ///"),_c('br'),_vm._v(" // ")])])]),(_vm.pending !== undefined)?_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center qr-generated mt-5"},[_c('div',{staticClass:"details-transfer",class:{ 'ml-5': !_vm.isMobile, 'd-flex flex-column': _vm.isMobile }},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation"}),_c('h3',{staticClass:"mt-3 white"},[_vm._v("Transaction details:")]),_c('h3',{staticClass:"grey"},[_vm._v(" The ticket NFT will be sent directly to ETH address typed. ")]),(_vm.pending)?_c('h3',{staticClass:"green"},[_vm._v(" Your transaction was submitted at: ")]):_vm._e(),(_vm.explorerUrl !== undefined)?_c('a',{staticClass:"green",attrs:{"href":_vm.explorerUrl + _vm.pending,"target":"_blank"}},[_vm._v(_vm._s(_vm.pending))]):_vm._e(),(_vm.explorerUrl === undefined)?_c('p',[_vm._v(_vm._s(_vm.pending))]):_vm._e()])]):_vm._e()]):_vm._e()]),(
            _vm.account &&
            !_vm.isClaiming &&
            !_vm.claimCompleted &&
            !_vm.isWorking &&
            !_vm.transferCompleted
          )?_c('div',[_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.nfts.length > 0 && !_vm.isClaiming)?_c('div',[_c('div',{staticClass:"ticket-select mt-3"},[_c('h5',{staticClass:"green"},[_vm._v("1. Choose an NFT ticket to entry")]),_c('p',[_vm._v(" Select an NFT ticket in your web3 wallet that you would like"),_c('br'),_vm._v(" to use to enter an NFTBERLIN event. ")]),_c('div',{staticClass:"ticket-box-container"},_vm._l((_vm.nfts),function(ticket,index){return _c('div',{key:index,staticClass:"ticket-box",on:{"click":function($event){return _vm.selectTicket(ticket)}}},[_c('div',[_c('img',{class:{
                          'ticket-selected':
                            _vm.tokenId === ticket.tokenId &&
                            _vm.tokenId !== undefined,
                        },attrs:{"src":ticket.metadata.image.replace(
                            'ar://',
                            'https://arweave.net/'
                          ),"alt":"ticket NFT Berlin"}})]),(ticket.claim !== null)?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"green mt-1"},[_vm._v(_vm._s(ticket.claim))])]):_vm._e()])}),0)]),_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(
                    _vm.tokenId !== null &&
                    _vm.tokenId !== undefined &&
                    _vm.ticketSelection === true
                  )?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"btn-mint mb-3",on:{"click":function($event){_vm.initClaimProcess = true;
                      _vm.initSendProcess = false;}}},[_vm._v(" GENERATE QR CODE ")]),_c('br'),_c('div',{staticClass:"btn-mint mb-3",on:{"click":function($event){_vm.initSendProcess = true;
                      _vm.initClaimProcess = false;}}},[_vm._v(" TRANSFER NFT ")])]):_vm._e()])],1):_vm._e()])],1):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-12 col-lg-4 offset-lg-1"},[(
            !_vm.isMobile &&
            (_vm.claimCompleted || _vm.transferCompleted) &&
            (_vm.claimed.qr !== undefined || _vm.pending !== undefined)
          )?_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"sqr-btn",on:{"click":function($event){_vm.tokenId = '';
              _vm.selected = {};
              _vm.initClaimProcess = false;
              _vm.initSendProcess = false;
              _vm.ticketSelection = false;
              _vm.claimCompleted = false;
              _vm.transferCompleted = false;
              _vm.connect();}}},[_c('i',{staticClass:"fa-solid fa-arrow-left"})])]):_vm._e(),_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(
              _vm.account &&
              _vm.initClaimProcess &&
              !_vm.isClaiming &&
              !_vm.claimCompleted &&
              !_vm.isWorking &&
              _vm.tokenId !== undefined &&
              _vm.tokenId !== ''
            )?_c('div',[(_vm.tokenId !== '' && _vm.initClaimProcess && !_vm.isClaiming)?_c('div',{staticClass:"claim-tickets mt-5"},[_c('div',{staticClass:"title-container d-flex justify-content-between align-items-end b-bottom pb-4"},[_c('h2',{staticClass:"green m-0"},[_vm._v(" enter "),_c('br'),_vm._v(" your email: ")]),_c('div',{staticClass:"sqr-btn",on:{"click":function($event){_vm.tokenId = '';
                    _vm.selected = '';
                    _vm.initClaimProcess = false;
                    _vm.ticketSelection = false;}}},[_c('i',{staticClass:"fa-solid fa-xmark"})])]),_c('p',{staticClass:"white mt-4"},[_vm._v(" A QR code will be sent directly to your email address. Make sure you have it with you (either in digital form or printed out) to guarantee admission to an event. ")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","placeholder":"Type your e-mail here.."},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"btn-mint mt-4",on:{"click":function($event){return _vm.claim()}}},[_vm._v("GENERATE")])]),(_vm.mailError.length > 0)?_c('p',{staticClass:"mt-1 red"},[_vm._v(" "+_vm._s(_vm.mailError)+" ")]):_vm._e()]):_vm._e()]):_vm._e()]),_c('Transition',{attrs:{"name":"custom-fade","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(
              _vm.account &&
              _vm.initSendProcess &&
              !_vm.isSending &&
              !_vm.transferCompleted &&
              !_vm.isWorking &&
              _vm.tokenId !== undefined &&
              _vm.tokenId !== ''
            )?_c('div',[(_vm.tokenId !== '' && _vm.initSendProcess && !_vm.isSending)?_c('div',{staticClass:"claim-tickets mt-5"},[_c('div',{staticClass:"title-container d-flex justify-content-between align-items-end b-bottom pb-4"},[_c('h2',{staticClass:"green m-0"},[_vm._v(" enter "),_c('br'),_vm._v(" ETH address ")]),_c('div',{staticClass:"sqr-btn",on:{"click":function($event){_vm.tokenId = '';
                    _vm.selected = '';
                    _vm.initSendProcess = false;
                    _vm.ticketSelection = false;}}},[_c('i',{staticClass:"fa-solid fa-xmark"})])]),_c('p',{staticClass:"white mt-4"},[_vm._v(" Send the Ticket NFT to your friend! Please make sure that the ETH address you are entering is correct. We cannot refund or recover your ticket if you send it to a non-existent or incorrect address. ")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.receiver),expression:"receiver"}],attrs:{"type":"text","placeholder":"Type ETH address here..."},domProps:{"value":(_vm.receiver)},on:{"input":function($event){if($event.target.composing){ return; }_vm.receiver=$event.target.value}}}),_c('div',{staticClass:"btn-mint mt-4",on:{"click":function($event){return _vm.send()}}},[_vm._v("SEND")])]),(_vm.mailError.length > 0)?_c('p',{staticClass:"mt-1 red"},[_vm._v(" "+_vm._s(_vm.mailError)+" ")]):_vm._e()]):_vm._e()]):_vm._e()])],1)])]),(_vm.account && _vm.nfts.length === 0 && _vm.noNfts)?_c('div',{staticClass:"container-workingMessage d-flex align-items-center justify-content-center"},[_vm._m(1)]):_vm._e(),(_vm.isWorking)?_c('div',{staticClass:"container-workingMessage d-flex flex-column flex-md-row align-items-center justify-content-center text-center"},[_c('i',{staticClass:"fas fa-spinner fa-pulse"}),_c('div',{staticClass:"workingMessage"},[_vm._v(_vm._s(_vm.workingMessage)+"...")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://goo.gl/maps/eZZQap8PjCvQzVAM6","target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center location"},[_c('i',{staticClass:"fa-solid fa-location-dot"}),_c('p',[_vm._v("Alte Münze, Berlin")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5 text-center"},[_c('p',{staticClass:"red"},[_vm._v("You don't have NFTs")]),_c('a',{attrs:{"href":"/ticket"}},[_c('div',{staticClass:"btn-mint"},[_vm._v("Buy your Tickets")])])])}]

export { render, staticRenderFns }