<template>
  <div class="talks-page">
    <Navbar />
    <MenuMobile class="hideDesktop" />
    <div class="schedule-content">
      <div class="talk-scheduling">
        <h1>Talk<br />scheduling</h1>
        <div class="tags">
          <div class="tag active-day">DAY 1</div>
          <a href="/talks-2"><div class="tag active-link">DAY 2</div></a>
        </div>
      </div>
      <div class="room-a">
        <div class="rooms">
          <h1>LOOKS RARE STAGE #1<br />First floor, Main hall</h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>

            <!--         looks rare stage #1
First floor, main hall -->

            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.30</p></div>
                <div class="col">
                  <p>
                    Viktor Diordiiev:<br />
                    Ex-Head of Economics and CCO, Arweave<br />
                    Enara Nazarova
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Web3 and the Russia-Ukraine war</p>
                </div>
                <div class="col">
                  <p>
                    How web3 tech and the global blockchain community are
                    helping Ukraine to win in the war against Russia? Why
                    Ukraine’s victory will accelerate the global movement toward
                    a freer (digital) world? We will also have a representative
                    of a Ukrainian charity and a Ukrainian NFT artist speak.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.30-11.15</p></div>
                <div class="col">
                  <p>
                    Adina Glickstein - Editor, Spike Magazine Tim <br />
                    Daubenschütz - Software Developer <br />
                    Ascribe Beatriz Ramos - Creator <br />
                    Dada Maria Paula Fernandez - Co-founder, JPEG"
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>What have we done? <br />The history of NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Discussing the topic of infrastructure, token standards and
                    the main actors both in art and tech
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.15-12.00</p></div>
                <div class="col">
                  <p>
                    Dr. David An - Partner, Greenfield One <br />
                    Luke Loo - NFT Lead, Polygon <br />
                    Robin Janaway - NFT Drop Lead, Outlier Ventures <br />
                    Bozena Rezab - CEO, Gamee <br />
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>The State of Play</p>
                </div>
                <div class="col">
                  <p>Panel discussing gaming in the NFT space.</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.30</p></div>
                <div class="col">
                  <p>Stephen Young - Founder <br />and CEO, NFTfi</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>The Advent of NFT Finance</p>
                </div>
                <div class="col">
                  <p>
                    An overview of NFT Financialization, the web3 vertical
                    comprising the infrastructure and markets for NFT-based
                    financial products and services.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.30-14.00</p></div>
                <div class="col">
                  <p>
                    Daniel Minsu Kim: <br />
                    Founder and CEO, NFTBank
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Approaches to NFT Valuation</p>
                </div>
                <div class="col">
                  <p>
                    This talk will dive into the different valuation methods
                    that are being used to price NFTs today, how other platforms
                    are leveraging them, and NFT Bank's data-driven approach to
                    NFT pricing.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>
                    Conor Moore - Co-founder, MetaStreet <br />
                    David Choi - Co-founder, MetaStreet
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Scaling Liquidity for NFT Lending Markets.</p>
                </div>
                <div class="col">
                  <p>
                    Discussion about the infrastructure needed to allow scaled
                    liquidity in the NFT lending space, as well as the
                    implications of metaverse capital markets and the future
                    battle to control NFT int. rates
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-15.00</p></div>
                <div class="col">
                  <p>
                    Hocwyn Tipwex <br />
                    Director of Technical Outreach<br />& Marketing, Uqbar
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>The Crypto OS</p>
                </div>
                <div class="col">
                  <p>
                    Old crypto was optimized for divisible quantities because
                    transactions were expensive and clumsy - and they still are.
                    Crypto’s future is a computational universe populated by
                    discrete objects, and their interactions. A rich application
                    environment - a p2p protocol plus mathematically-verifiable
                    transaction scaling - is what transforms NFTs into a
                    cryptoverse. A platform for building p2p networked apps
                    without transaction scaling is limited to being a toy
                    decoration.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.30</p></div>
                <div class="col">
                  <p>
                    Joel Thorstensson - Co-founder,<br />
                    Ceramic network
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>The role of NFTs in data protocols</p>
                </div>
                <div class="col">
                  <p>
                    NFTs are great for many things, but are they enough for
                    social, DAO, and science use cases? In this talk we explore
                    the boundaries between verifiable data and NFTs.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.30-16.15</p></div>
                <div class="col">
                  <p>
                    Tyler Golato: <br />
                    Co-founder and CSO, Molecule & Founding Steward, VitaDaO and
                    PsyDAO. <br />
                    Vincent: Founder, VitaDAO <br />Niklas Rindtorff: Founder,
                    LabDAO <br />Paul Kohlhaas: Founder, Molecule"
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>The Role of IP-NFTs in DeSci</p>
                </div>
                <div class="col">
                  <p>
                    NFTs exploded out of the ether in recent years. Most would
                    agree that the space has been instrumental in helping to
                    usher in a “creator economy”, transferring power away from
                    middlemen and towards creatives themselves. IP-NFTs extend
                    this creator economy to the sciences for the first time.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.15-16.45</p></div>
                <div class="col">
                  <p>
                    George Spasov <br />
                    Co-founder, LimeChain & Partner, EnterDAO
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>EIP4400 - Enabling the NFT renting tsunami</p>
                </div>
                <div class="col">
                  <p>
                    EIP4400 is a draft standard for NFT renting, already
                    recognized by major players in the NFT world. In this talk,
                    I will share how it works and how it enables the large scale
                    renting economy to begin.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-17.30</p></div>
                <div class="col">
                  <p>
                    Philipp Huebner <br />
                    CEO, Unbound Nation
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>The future of web3 Games</p>
                </div>
                <div class="col">
                  <p>
                    In this talk we will take a look at the current state of the
                    industry and the different players in it, its currently
                    predominant model of play-to-earn and provide possible
                    scenarios for future developments.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.30-18.00</p></div>
                <div class="col">
                  <p>
                    Thomas Zaepffel - Co-founder and CEO,<br />
                    Immortal Game
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Immortal Game: bringing NFTs to a 1,000 year old game</p>
                </div>
                <div class="col">
                  <p>
                    Thomas is the CEO of Immortal Games and will share insights
                    about his company and the web3 space.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="schedule-divider"></div>

        <!-- ROOM 2 -->

        <div class="rooms">
          <h1>
            GM STAGE #2:<br />
            First floor, opposite to the main hall
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME SLOT</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.30</p></div>
                <div class="col">
                  <p>Teun Habraken - Product Manager, Litentry</p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Detailed audience insights for NFT artists</p>
                </div>
                <div class="col">
                  <p>
                    VC's (Verifiable credentials) & DID's (Decentralised
                    Identifiers) are soon bringing identity data to the
                    pseudonymous NFT audience. They will help NFT attract a more
                    loyal and receptive audience.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.30-11.00</p></div>
                <div class="col">
                  <p>
                    Fabian Burghardt <br />
                    Co-founder, ravespace.io
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Rethinking NFT's - Utility in the Metaverse</p>
                </div>
                <div class="col">
                  <p>
                    We've developed our own 3D Web Engine and built 7 Metaverse
                    projects in the web3 space within the last year. Our focus
                    lies on integrating seamless NFT utilities into virtual
                    worlds.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.00-11.30</p></div>
                <div class="col">
                  <p>Anett Rolikova - Lead, NFT Standards Working Group</p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>ERC-721 as failed attempt in standardisation of NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Talk describing current NFT smart contract standards,
                    highlighting issues with ERC-721, ERC-1155 and potential
                    solutions. Representing NFT Standards research and
                    documentation
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.30-12.00</p></div>
                <div class="col">
                  <p>Carsten van Husen - Founder, Eleet Games</p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>
                    NFT optional - How to show their beauty to those who hate
                    them
                  </p>
                </div>
                <div class="col">
                  <p>
                    No Freaking Thanks - 70% of active gamers hate NFTs
                    according to recent studies. Yet, cryptoheads are looking
                    towards that huge tech-savvy audience like to nearly no
                    other. Both groups have their good reasons. How to bridge
                    the gap? Carsten will provide his personal answer, on the
                    back of having 'sold' Free-to-Play as an intially undesired
                    concept to 500M players starting 15 years ago.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.30</p></div>
                <div class="col">
                  <p>Emily Rosa Shahaj - CEO, Gravity the Studio</p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>The Metaverse and You: Virtual Identity as Currency</p>
                </div>
                <div class="col">
                  <p>
                    Punks and Apes abound as Twitter profile pics. What are the
                    implications of using NFTs as our digital identities, now
                    and into games and immersive metaverses?
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13:30-14.00</p></div>
                <div class="col">
                  <p>Roman Frank - Founder, Legendary Play</p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Why we moved from Web2 to Web3 with our games startup</p>
                </div>
                <div class="col">
                  <p>
                    The talk will cover the following points: why it makes sense
                    for their games startup bussines wise; why it makes sense
                    for their audience/gamers; their take on free2play VS play
                    to earn.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>
                    Edua Sykes <br />
                    Events Manager, KnownOrigin
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Digital Fashion: So hot rn</p>
                </div>
                <div class="col">
                  <p>
                    An induction to digital fashion, how this is created, how
                    people can wear it, and how this can be super powered via
                    NFTs.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-15.00</p></div>
                <div class="col">
                  <p>Tina - Head of Design, Element Finance</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Yielding the future of NFT Opportunities</p>
                </div>
                <div class="col">
                  <p>
                    How we can bridge the intersection of defi protocols and
                    NFTs and visualize the contributions and actions of
                    community members past their portfolio balance.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.30</p></div>
                <div class="col">
                  <p>Diego - Co-founder, Gradient Protocol</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Scalable Approaches to NFT Lending</p>
                </div>
                <div class="col">
                  <p>
                    Explore the current state of affairs, the unique challenges
                    facing NFT lending, and exploring the end state for how NFT
                    lending plays out- where it expands beyond art.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.30-16.00</p></div>
                <div class="col">
                  <p>Tom Pandolfi - Founder, Honey Finance</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>How NFTs can fix DeFi</p>
                </div>
                <div class="col">
                  <p>
                    NFTs have entered the main stream in many parts of the
                    world. Through NFTs (gaming / art / etc) will come a new
                    wave of potential DeFi users and a new gamified UI/UX for
                    DeFi.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.00-16.30</p></div>
                <div class="col">
                  <p>Ricardo J. Méndez - Head of Governance, Marinade</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Eating live octopus</p>
                </div>
                <div class="col">
                  <p>
                    Marinade just launched a governance experiment combining
                    NFTs and fungible tokens. This has so far led to both
                    expected and surprising behavior from users - let's discuss.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.30-16.40</p></div>
                <div class="col">
                  <p>
                    Dennis Peqas <br />
                    Founder, NFT Club Berlin
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Local NFT community</p>
                </div>
                <div class="col">
                  <p>
                    A local community helps the city to grow and iterate faster.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.40-17.05</p></div>
                <div class="col">
                  <p>Denis Daiber <br />Crypto OG - Head of Trading Nuri</p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Pre-Punk NFTs on Bitcoin (and previous)</p>
                </div>
                <div class="col">
                  <p>
                    How NFTs started in 2014 on NMC, came to life on BTC and how
                    they're accessible on ETH
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.05-17.30</p></div>
                <div class="col">
                  <p>
                    Leila Ismailova <br />
                    Special Projects Curator, ARTISANT
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>
                    Fashion 3.0: Avatars, Metaverse, Freedom, Sustainability
                  </p>
                </div>
                <div class="col">
                  <p>
                    We create a new layer of fashion that helps Earth to
                    breathe, allows creators to be truly free. It is a new
                    concept of how without overconsuming and polluting one can
                    look fabulous.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.30-18.00</p></div>
                <div class="col">
                  <p>
                    Timo Springer <br />
                    Twire <br />Jan Karnath <br />
                    Co-Founder and Ceo Timeless
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>The service DAO revolution / Cro Nft</p>
                </div>
                <div class="col">
                  <p>The service DAO revolution</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ROOM 3 -->

        <div class="rooms">
          <h1>
            MOON STAGE #3:<br />
            Top floor, Olivia Studio
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME SLOT</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.30</p></div>
                <div class="col">
                  <p>Emiliano Bonassi - Co-founder, Rentable</p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>
                    Unleash NFTs value with free collateral rentals and
                    composability
                  </p>
                </div>
                <div class="col">
                  <p>
                    Free collateral rentals enable users to access NFTs at
                    fraction of their price and owners to put them to work.
                    Explore together how this mixed with composability brings
                    innovative use-cases!
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.30-10.50</p></div>
                <div class="col">
                  <p>
                    Jasmine - General Partner<br />
                    A&T Capital <br />
                    Justin Sun - Founder, Tron
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>The Future Perspective</p>
                </div>
                <div class="col">
                  <p>
                    Imagine NFT's finances and ecosystem in 2032. A conversation
                    with Justin Sun looks at the current state of affairs from
                    the perspective of an investor and a collector. Join the
                    conversation for a glimpse into the future as you explore
                    how NFT's financial mechanisms and ecosystem will evolve.
                  </p>
                </div>
              </div>

              <div class="row programrow">
                <div class="col schedule-hours"><p>10.50-11.10</p></div>
                <div class="col">
                  <p>
                    Maria Magdalena Neu <br />
                    Community Manager and Guild Lead, Mintbase<br />
                    Yiannis Bampis <br />
                    Community Manager, TENK
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>NFT Infrastructure on NEAR</p>
                </div>
                <div class="col">
                  <p>
                    A Dialogue and Presentation about how Utility NFTs meets
                    Gernerative Art
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.10-12.00</p></div>
                <div class="col">
                  <p>
                    Anika Meier: Writer and Curator <br />
                    Simon Denny: Artist <br />
                    Sarah Friend: Artist <br />
                    Harm van den Dorpel: Artist<br />
                    Johanna Neuschäfer: Co-founder, Office Impart<br />
                    Susanne Päch: Journalist,<br />
                    Managing director of mce mediacomeurope
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Exploring Emergent Technology.</p>
                </div>
                <div class="col">
                  <p>The Past and Future of Art on the Blockchain</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-13.30</p></div>
                <div class="col">
                  <p>
                    Pedro Granate:<br />
                    Co-founder and COO, Zharta
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>UX on NFT-collateralized Loans</p>
                </div>
                <div class="col">
                  <p>
                    Discussing the current UX friction of getting loans on your
                    NFTs and how we can address them.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.30-14.00</p></div>
                <div class="col">
                  <p>
                    Anjali Young: <br />
                    Co-founder and Chief <br />Community Officer, Collab.Land
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>NFT Communities: Then and Now</p>
                </div>
                <div class="col">
                  <p>
                    A brief history of the origins of NFT communities from the
                    people who powered them from Day One. Sharing what we've
                    seen and what we see coming.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-14.30</p></div>
                <div class="col">
                  <p>
                    Jose Nuno Pinto:<br />
                    Web3 Lawyer, MJP PARTNERS
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>NFT Law and Regulation</p>
                </div>
                <div class="col">
                  <p>
                    Legal considerations on NFTs: Ownership, Licensing,
                    Royalties, Taxes and Regulation. This session will also
                    include Q&A. It is not legal advice!
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.30-15.00</p></div>
                <div class="col">
                  <p>
                    Carlo Moretti: <br />
                    Founder, Overdroid and Hexarchia
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Hexarchia: Where Ethereum meets War&Strategy</p>
                </div>
                <div class="col">
                  <p>
                    Presenting for the first time to the community our plans to
                    bring a world-class strategy game with a balanced gamaplay
                    design and unique economic incentives.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-15.30</p></div>
                <div class="col">
                  <p>
                    Bradley Clarke: <br />
                    Creative Technologist
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>P2E: Shall we Ride the Basilisk?</p>
                </div>
                <div class="col">
                  <p>
                    The Basilisk hunts us all, its ugly maw constantly prepared
                    to devour our data. Can this be prevented? Is it part of the
                    human condition? If we cannot shape the machine, how could
                    we adapt to it?
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.30-16.00</p></div>
                <div class="col">
                  <p>
                    Jouni Helminen: <br />
                    Director of Design, Aave
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Lens protocol - decentralised social media with NFTs</p>
                </div>
                <div class="col">
                  <p>
                    Having a portable, shared social graph and p2p value
                    transfer between users (instead of ad driven mechanics and
                    walled gardens) offers interesting, fresh possibilities for
                    creators, users and devs.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.00-16.30</p></div>
                <div class="col">
                  <p>
                    Robert Schlunze: <br />
                    Artist/Founder<br />
                    Florian Aplowski: <br />
                    Dev/Founder
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>ROKATOONZ RADIO PICTURES</p>
                </div>
                <div class="col">
                  <p>Transformation - the road from Web2 to Web3</p>
                </div>
              </div>

              <div class="row programrow">
                <div class="col schedule-hours"><p>16.30-17.00</p></div>
                <div class="col">
                  <p>
                    Kabuki: <br />
                    Music producer and Lecturer
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Quantum Rainbows - a beat tape on the blockchain</p>
                </div>
                <div class="col">
                  <p>
                    This session provides insights into my experiments with
                    various NFT music platforms, showcasing creative
                    applications of web3 tools for forward-thinking musicians
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-17.20</p></div>
                <div class="col">
                  <p>
                    Rebecca Miller: <br />Signer of the Bright Moments sub-DAO
                    Berlin<br />
                    Malte Rauch: <br />Bright Moments Partnerships Manager,
                    Europe & Signer of the sub-DAO Berlin
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>The Bright Moments DAO: Around the World in 10,000 NFTs</p>
                </div>
                <div class="col">
                  <p>The Bright Moments DAO: Around the World in 10,000 NFTs</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.20-17.40</p></div>
                <div class="col">
                  <p>
                    Paul Schmidt: <br />
                    Founders Associate, Fractal Protocol
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Making generative Art</p>
                </div>
                <div class="col">
                  <p>
                    Bootstrapped nft marketplace on tezos. More than 18m$ since
                    starting in nov 21. Full release of the platform in April.
                    Only Generative Art. talk about struggles building,
                    community building & gen art
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ROOM 4 -->

        <div class="rooms">
          <h1>
            THE LAB #4:<br />
            First floor, foyer in front of the main hall
          </h1>
          <div class="container-fluid">
            <div class="row greenrow">
              <div class="col">
                <h2>TIME SLOT</h2>
              </div>
              <div class="col">
                <h2>SPEAKER</h2>
              </div>
              <div class="col">
                <h2>TRACK</h2>
              </div>
              <div class="col">
                <h2>TITLE</h2>
              </div>
              <div class="col">
                <h2>DESCRIPTION</h2>
              </div>
            </div>
            <div class="schedule-program">
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.00-10.45</p></div>
                <div class="col">
                  <p>
                    Joshia Seam:<br />
                    Co-founder, Zesty Market
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>Metaverse Marketing</p>
                </div>
                <div class="col">
                  <p>
                    Learn how to market and advertise things in the Metaverse.
                    We’ll be sharing current best practices and tips on how to
                    leverage on this new medium to reach new audiences. Zesty
                    Market has been working in this domain, from various land
                    projects to xr, and we have unique insights and practical
                    tips for people who want to dive into the field. We’ll
                    introduce people to various tools that can be used to
                    promote projects and to maximize their earnings through
                    advertising income. Targeted audiences: NFT project owners,
                    Marketing people, Project founders, Brand managers,
                    Community Managers"
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>10.45-11.30</p></div>
                <div class="col">
                  <p>
                    Alper Akalin:<br />
                    Founder and Researcher, ARSUITE
                  </p>
                </div>
                <div class="col">
                  <p>2: Games & Experiences</p>
                </div>
                <div class="col">
                  <p>The Immersive Metaverse Experiences</p>
                </div>
                <div class="col">
                  <p>
                    How to create photorealistic 3D & XR & AR featured,
                    placeable, wearable, printable Immersive 3D NFT collections
                    in seconds within Threedfy Cloud-Native Designer Studio with
                    your 3D models
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>11.30-12.15</p></div>
                <div class="col">
                  <p>Jan Ellwitz - Harmony Germany DAO Governor</p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>
                    Bridges and NFTs - how to bridge your ERC721 token in a few
                    steps
                  </p>
                </div>
                <div class="col">
                  <p>
                    We will bridge NFTs and i will point to the layer zero
                    protocol
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>13.00-14.00</p></div>
                <div class="col">
                  <p>
                    Mahoney Turnbull:<br />
                    Community + Culture strategist, Frothy Cofounder<br />
                    Blaise Turnbull:<br />
                    Community + Culture strategist, Frothy Cofounder
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Regenerative Culture Design for NFT ecosystems</p>
                </div>
                <div class="col">
                  <p>
                    We will explore how community + culture within the NFT space
                    can be designed and developed in regenerative ways, that
                    goes beyond temporary societal intrigue to deeper circular
                    systems.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>14.00-15.00</p></div>
                <div class="col">
                  <p>
                    Bengisu Berispek:<br />Founder of Sustain.ALL & RHEA
                    Collective
                  </p>
                </div>
                <div class="col">
                  <p>1: Art, Collectibles & Society</p>
                </div>
                <div class="col">
                  <p>Workshop on Sustainable NFTs</p>
                </div>
                <div class="col">
                  <p>How to bring & support sustainability in NFT World?</p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>15.00-16.00</p></div>
                <div class="col">
                  <p>
                    Maria Magdalena Neu:<br />
                    Community Manager<br />
                    and Guild Lead, Mintbase
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>
                    Mintbase. Getting your hands dirty with NFTs. Manage your
                    own smart contract and interact with it via your DAO on
                    NEAR.
                  </p>
                </div>
                <div class="col">
                  <p>
                    Mintbase encompasses an NFT toolkit with which you can
                    deploy marketplaces & integrate them onto your own domain,
                    metaverse, or Dapp. DAOs can ACT on smart contracts.
                    Possibilities? Endless!
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>16.00-17.00</p></div>
                <div class="col">
                  <p>
                    Nathan van der Heyden:<br />
                    Ecosystem Lead, Snapshot
                  </p>
                </div>
                <div class="col">
                  <p>4: Infrastructure & Research</p>
                </div>
                <div class="col">
                  <p>Governance Best Practices with Snapshot</p>
                </div>
                <div class="col">
                  <p>
                    The beauty of the NFT space comes from its communities. Good
                    communities are made better by good governance mechanisms.
                  </p>
                </div>
              </div>
              <div class="row programrow">
                <div class="col schedule-hours"><p>17.00-18.00</p></div>
                <div class="col">
                  <p>
                    Diogo Pires:<br />
                    Co-founder and CTO, Zharta
                  </p>
                </div>
                <div class="col">
                  <p>3: Finance</p>
                </div>
                <div class="col">
                  <p>Building NFT Contracts in Vyper</p>
                </div>
                <div class="col">
                  <p>
                    A workshop for developers who are looking to develop NFT
                    contracts on Vyper, an enhanced EVM-compatible smart
                    contract language
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MenuMobile from "@/components/MenuMobile.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "TalksPage",

  components: {
    Navbar,
    MenuMobile,
    Footer,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {},
};
</script>

<style>
</style>