var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket"},[(!_vm.isMobile)?_c('Navbar'):_vm._e(),(_vm.isMobile)?_c('MenuMobile'):_vm._e(),_c('div',{staticClass:"gap hideMobile"}),_c('div',{staticClass:"container mb-5 ipad-margin-top",class:{ 'pt-5': !_vm.isMobile }},[_c('div',[_c('div',{staticClass:"title-container",class:{ 'mt-5': !_vm.isMobile }},[_c('h1',{staticClass:"m-0"},[_vm._v("2-DAY")]),_c('h2',{staticClass:"m-0"},[_vm._v("ticket")]),_c('h3',{staticClass:"m-0"},[_vm._v("nftberlin")]),_c('h3',{staticClass:"m-0"},[_vm._v("unconference")]),_vm._m(0)]),_vm._m(1)])]),_c('div',{staticClass:"container mb-5"},[(!_vm.loaded)?_c('div',{staticClass:"workingMessage pt-2 mt-5 mb-5"},[_c('i',{staticClass:"fas fa-spinner fa-pulse"}),_vm._v(" Loading ticket informations, please wait.. ")]):_vm._e(),(_vm.loaded)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-4"},[_c('div',{staticClass:"ticket-card"},[_c('div',[_c('div',[_c('div',{class:{ 'd-flex': _vm.isMobile }},[_c('div',{staticClass:"ticket-title",class:{
                    green: _vm.info['EARLYBIRD'].active === true,
                    green_inactive: _vm.info['EARLYBIRD'].active === false,
                  }},[_vm._v(" Bird / 2-day admission/ ")]),_c('div',{staticClass:"ticket-title red"},[_vm._v("-60% discount LFG!!!")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"ticket-price",class:{
                    green: _vm.info['EARLYBIRD'].active === true,
                    green_inactive: _vm.info['EARLYBIRD'].active === false,
                  }},[_vm._v(" "+_vm._s(_vm.info["EARLYBIRD"].price)+"€ ")]),(_vm.isMobile)?_c('div',{staticClass:"ticket-details",on:{"click":function($event){_vm.openDetails1 = !_vm.openDetails1}}},[_vm._v(" details "),(_vm.openDetails1)?_c('span',[_vm._v("+")]):_vm._e(),(!_vm.openDetails1)?_c('span',[_vm._v("-")]):_vm._e()]):_vm._e()])]),_c('Transition',{attrs:{"name":"slide"}},[(_vm.openDetails1)?_c('div',{staticClass:"mt-3 p-3",class:{
                  white: _vm.info['EARLYBIRD'].active === true,
                  white_inactive: _vm.info['EARLYBIRD'].active === false,
                }},[_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ 2-day admission to the unconference on May 25-26, 2022 at Alte Münze, Berlin ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Eligible for an NFT crypto art ticket ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Admission to the NFTBERLIN After-Party ")])]):_vm._e()])],1),_c('a',{attrs:{"href":"/ticket/earlybird"}},[(
                _vm.info['EARLYBIRD'] !== undefined &&
                _vm.info['EARLYBIRD'].minted < _vm.info['EARLYBIRD'].limit &&
                _vm.info['EARLYBIRD'].active === true
              )?_c('div',{staticClass:"btn-ticket"},[_vm._v(" BUY TICKET ")]):_vm._e()]),(
              _vm.info['EARLYBIRD'] !== undefined &&
              _vm.info['EARLYBIRD'].active === false
            )?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" COMING SOON ")]):_vm._e(),(
              _vm.info['EARLYBIRD'] !== undefined &&
              _vm.info['EARLYBIRD'].minted === _vm.info['EARLYBIRD'].limit
            )?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" SOLD OUT ")]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-4",class:{ 'mt-5': _vm.isMobile }},[_c('div',{staticClass:"ticket-card"},[_c('div',[_c('div',{class:{
                green: _vm.info['SECONDWAVE'].active === true,
                green_inactive: _vm.info['SECONDWAVE'].active === false,
              }},[_c('div',{staticClass:"ticket-title"},[_vm._v("Public / 2-day admission/")]),(!_vm.isMobile)?_c('div',{staticClass:"mt-2"}):_vm._e(),(!_vm.isMobile)?_c('br'):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"ticket-price",class:{
                    green: _vm.info['SECONDWAVE'].active === true,
                    green_inactive: _vm.info['SECONDWAVE'].active === false,
                  }},[_vm._v(" "+_vm._s(_vm.info["SECONDWAVE"].price)+"€ ")]),(_vm.isMobile)?_c('div',{staticClass:"ticket-details",on:{"click":function($event){_vm.openDetails2 = !_vm.openDetails2}}},[_vm._v(" details "),(_vm.openDetails2)?_c('span',[_vm._v("+")]):_vm._e(),(!_vm.openDetails2)?_c('span',[_vm._v("-")]):_vm._e()]):_vm._e()])]),_c('Transition',{attrs:{"name":"slide"}},[(_vm.openDetails2)?_c('div',{staticClass:"mt-3 p-3",class:{
                  white: _vm.info['SECONDWAVE'].active === true,
                  white_inactive: _vm.info['SECONDWAVE'].active === false,
                }},[_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ 2-day admission to the unconference on May 25-26, 2022 at Alte Münze, Berlin ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Eligible for an NFT crypto art ticket ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Admission to the NFTBERLIN After-Party ")])]):_vm._e()])],1),_c('a',{attrs:{"href":"/ticket/public"}},[(
                _vm.info['SECONDWAVE'] !== undefined &&
                _vm.info['SECONDWAVE'].minted < _vm.info['SECONDWAVE'].limit &&
                _vm.info['SECONDWAVE'].active === true
              )?_c('div',{staticClass:"btn-ticket"},[_vm._v(" BUY TICKET ")]):_vm._e()]),(
              _vm.info['SECONDWAVE'] !== undefined &&
              _vm.info['SECONDWAVE'].active === false
            )?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" SOLD OUT ")]):_vm._e(),(
              _vm.info['SECONDWAVE'] !== undefined &&
              _vm.info['SECONDWAVE'].minted === _vm.info['SECONDWAVE'].limit
            )?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" SOLD OUT ")]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-4",class:{ 'mt-5': _vm.isMobile }},[_c('div',{staticClass:"ticket-card"},[_c('div',[_c('div',{class:{
                green: _vm.info['VIP'].active === true,
                green_inactive: _vm.info['VIP'].active === false,
              }},[_c('div',{staticClass:"d-flex flex-row flex-md-column flex-lg-column"},[_c('div',{staticClass:"ticket-title"},[_vm._v("Patron / 2-day admission")]),_c('div',{staticClass:"ticket-title",class:{ 'mx-2': _vm.isMobile }},[_vm._v(" + Private event ")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"ticket-price",class:{
                    green: _vm.info['VIP'].active === true,
                    green_inactive: _vm.info['VIP'].active === false,
                  }},[_vm._v(" "+_vm._s(_vm.info["VIP"].price)+"€ ")]),(_vm.isMobile)?_c('div',{staticClass:"ticket-details",on:{"click":function($event){_vm.openDetails3 = !_vm.openDetails3}}},[_vm._v(" details "),(_vm.openDetails3)?_c('span',[_vm._v("+")]):_vm._e(),(!_vm.openDetails3)?_c('span',[_vm._v("-")]):_vm._e()]):_vm._e()])]),_c('Transition',{attrs:{"name":"slide"}},[(_vm.openDetails3)?_c('div',{staticClass:"mt-3 p-3",class:{
                  white: _vm.info['VIP'].active === true,
                  white_inactive: _vm.info['VIP'].active === false,
                }},[_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ 2-day admission to the unconference on May 25-26, 2022 at Alte Münze, Berlin ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Eligible for an NFT crypto art ticket ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Eligible for rare NFT crypto art collectibles ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Admission to the NFTBERLIN After-Party ")]),_c('div',{staticClass:"ticket-text"},[_vm._v(" ^ Admission to a private event with speakers and artists ")])]):_vm._e()])],1),_c('a',{attrs:{"href":"/ticket/patron"}},[(
                _vm.info['VIP'] !== undefined &&
                _vm.info['VIP'].minted < _vm.info['VIP'].limit &&
                _vm.info['VIP'].active === true
              )?_c('div',{staticClass:"btn-ticket"},[_vm._v(" BUY TICKET ")]):_vm._e()]),(_vm.info['VIP'] !== undefined && _vm.info['VIP'].active === false)?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" COMING SOON ")]):_vm._e(),(
              _vm.info['VIP'] !== undefined &&
              _vm.info['VIP'].minted === _vm.info['VIP'].limit
            )?_c('div',{staticClass:"btn-ticket btn-disabled"},[_vm._v(" SOLD OUT ")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"gap"})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specs-location d-flex align-items-start mt-4"},[_c('h4',[_vm._v("25 - 26 May, 2022")]),_c('a',{attrs:{"href":"https://goo.gl/maps/eZZQap8PjCvQzVAM6","target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center location"},[_c('i',{staticClass:"fa-solid fa-location-dot"}),_c('p',[_vm._v("Alte Münze, Berlin")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-section mt-5"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col-12 col-md-2 col-lg-2"},[_c('div',{staticClass:"cat hideMobile displayIpad"},[_c('img',{attrs:{"src":require("../assets/cat.png"),"alt":""}})])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('div',{staticClass:"cta-text"},[_vm._v(" money can’t buy happiness but it can buy nftberlin tickets ")])]),_c('div',{staticClass:"col-12 col-md-2 col-lg-2"},[_c('div',{staticClass:"cat hideMobile displayIpad"},[_c('img',{attrs:{"src":require("../assets/cat.png"),"alt":""}})])])])])}]

export { render, staticRenderFns }