<template>
  <div class="partners">
    <div class="container-fluid">
      <div class="row arrows">
        <svg
          width="57"
          height="77"
          viewBox="0 0 57 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.2 65.2197V76.9021H22.8V65.2197H34.2ZM22.8 53.5374V65.2197H11.4V53.5374H22.8ZM34.2 53.5374H45.6V65.2197H34.2V53.5374ZM11.4 41.855V53.5374H0V41.855H11.4ZM45.6 41.855H57V53.5374H45.6V41.855Z"
            fill="#00FF00"
          />
          <path d="M34 0.623047H23V54.2246H34V0.623047Z" fill="#00FF00" />
        </svg>
        <svg
          width="57"
          height="77"
          viewBox="0 0 57 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.2 65.2197V76.9021H22.8V65.2197H34.2ZM22.8 53.5374V65.2197H11.4V53.5374H22.8ZM34.2 53.5374H45.6V65.2197H34.2V53.5374ZM11.4 41.855V53.5374H0V41.855H11.4ZM45.6 41.855H57V53.5374H45.6V41.855Z"
            fill="#00FF00"
          />
          <path d="M34 0.623047H23V54.2246H34V0.623047Z" fill="#00FF00" />
        </svg>
        <svg
          width="57"
          height="77"
          viewBox="0 0 57 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.2 65.2197V76.9021H22.8V65.2197H34.2ZM22.8 53.5374V65.2197H11.4V53.5374H22.8ZM34.2 53.5374H45.6V65.2197H34.2V53.5374ZM11.4 41.855V53.5374H0V41.855H11.4ZM45.6 41.855H57V53.5374H45.6V41.855Z"
            fill="#00FF00"
          />
          <path d="M34 0.623047H23V54.2246H34V0.623047Z" fill="#00FF00" />
        </svg>
      </div>
    </div>

    <div class="row partners-content">
      <div class="col-lg-4">
        <a href="https://www.berlin-partner.de/en" target="_blank">
          <div>
            <img src="../assets/partners/berlincity.png" draggable="false" />
          </div>
        </a>
      </div>

      <div class="col-lg-4">
        <a href="https://sadgirlsbar.io/" target="_blank">
          <div>
            <img src="../assets/partners/Logo.png" draggable="false" />
          </div>
        </a>
      </div>

      <div class="col-lg-4" style="border:none">
        <a href="https://www.btc-echo.de/" target="_blank">
          <div>
            <img src="../assets/partners/btcecho.png" draggable="false" />
          </div>
        </a>
      </div>
      <div class="col-lg-4" >
        <a href="https://https://twitter.com/mempoolclub/" target="_blank">
          <div>
            <img src="../assets/partners/mempool.png" draggable="false" />
          </div>
        </a>
      </div>
      <div class="col-lg-4" >
        <a href="https://blnft.io/" target="_blank">
          <div>
            <img src="../assets/partners/somelogo.svg" draggable="false" />
          </div>
        </a>
      </div>
      <div class="col-lg-4" style="border:none">
        <a href="https://beincrypto.com/" target="_blank">
          <div>
            <img src="../assets/partners/beincrypto.svg" draggable="false" />
          </div>
        </a>
      </div>
      <div class="col-lg-4" >
        <a href="https://https://popkudamm.berlin/" target="_blank">
          <div>
            <img src="../assets/partners/popkdm.png" draggable="false" />
          </div>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style>
</style>